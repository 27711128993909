
import React from 'react';

const Features = () => {
    return (
        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
            <div id="features" className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="max-w-xl mx-auto text-center xl:max-w-2xl">
                    <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl xl:text-5xl font-pj">Features that matter</h2>
                </div>

                <div className="grid max-w-4xl grid-cols-1 mx-auto mt-8 text-center gap-y-4 sm:gap-x-8 sm:grid-cols-2 sm:mt-12 lg:mt-20 sm:text-left">
                    <div className="space-y-4 sm:space-y-8">
                        <div className="overflow-hidden bg-white shadow-md rounded-xl">
                            <div className="p-9">
                                <svg className="w-12 h-12 mx-auto text-gray-400 sm:mx-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <h3 className="mt-8 text-2xl font-bold text-gray-900 sm:mt-20 font-pj">Audience targeting</h3>
                                <p className="mt-6 text-base text-gray-600 font-pj">Enable precise timing of specific views, triggered by events and page URL rules, ensuring they appear when users are most engaged and comprehending their needs rapidly.</p>
                                <p className="mt-6 text-base text-gray-600 font-pj">Customize user targeting according to performed or unperformed events, as well as user attributes, to gather valuable user feedback and deliver a personalized experience on a large scale.</p>
                            </div>
                        </div>

                        <div className="overflow-hidden bg-white shadow-md rounded-xl">
                            <div className="p-9">
                                <svg className="w-12 h-12 mx-auto text-gray-400 sm:mx-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                                </svg>
                                <h3 className="mt-8 text-2xl font-bold text-gray-900 sm:mt-20 font-pj">Light SDK integration</h3>
                                <p className="mt-6 text-base text-gray-600 font-pj">InstaDiv's SDK integration is extremly smooth and takes only a few lines of code</p>
                                <p className="mt-6 text-base text-gray-600 font-pj">Once integrated, InstaDiv's powerful WYSIWYG editor allows you to customize and control the views instantly without going through play/app store updates. </p>
                            </div>
                        </div>
                    </div>

                    <div className="space-y-4 sm:space-y-8">
                        <div className="relative">
                            <div className="absolute -inset-1">
                                <div className="w-full h-full rotate-180 opacity-30 blur-lg filter" style={{ background: "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)" }}></div>
                            </div>

                            <div className="relative overflow-hidden bg-white shadow-md rounded-xl">
                                <div className="p-9">
                                    <svg className="w-12 h-12 mx-auto text-gray-400 sm:mx-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
                                    </svg>
                                    <h3 className="mt-8 text-2xl font-bold text-gray-900 sm:mt-20 font-pj">Firebase integration</h3>
                                    <p className="mt-6 text-base text-gray-600 font-pj">Securely connects to your Firebase account to keep the cost minimum as Firebase offers a generous free tier and pricing structure. This also helps in keeping your data in your account.</p>
                                    <p className="mt-6 text-base text-gray-600 font-pj">Instadiv uses Firebase for storing data in Firestore DB with very optimised read & write operations.</p>
                                </div>
                            </div>
                        </div>

                        <div className="overflow-hidden bg-white shadow-md rounded-xl">
                            <div className="p-9">
                                <svg className="w-12 h-12 mx-auto text-gray-400 sm:mx-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                                </svg>
                                <h3 className="mt-8 text-2xl font-bold text-gray-900 sm:mt-20 font-pj">Customize UI</h3>
                                <p className="mt-6 text-base text-gray-600 font-pj">Customize & experiment to make UI in-line with your theme. Deliver a delightful user experience that seamlessly aligns with your brand identity. Change colors, dimensions etc remotely</p>
                                <p className="mt-6 text-base text-gray-600 font-pj">Manage CTAs, deeplink & landing experience from the cloud dashboard itself without going through app store updates.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative max-w-lg mx-auto mt-14">
                    <div className="max-w-3xl mx-auto text-center">

                        <a
                            href="https://app.instadiv.com" target="_blank" rel="noreferrer"
                            title=""
                            className="inline-flex items-center justify-center w-full px-10 py-4 text-lg font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                            role="button"
                        >
                            Get started for free
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Features;