import './App.css';
import Home from './routes/home';
import ReactGA from "react-ga4";

ReactGA.initialize("G-JNKCPDXYH8");
function App() {
  ReactGA.send({ hitType: "Landing", page: window.location.href, title: "InstaDiv" });
  ReactGA.event({
    category: "Screen",
    action: "Landing",
    label: window.location.href
  });
  return (
    <>
      <Home/>
    </>
  );
}

export default App;
