import React from 'react';

const HowItWorks = () => {        
    return (
        <section className="py-12 bg-white sm:py-16 lg:py-20">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">How it works?</h2>
                    <p className="max-w-md mx-auto mt-5 text-base font-normal text-gray-600 font-pj">InstaDiv works on an approach called Server Driven UI where a server is responsible for generating and sending the interface to the client. </p>
                </div>

                <p className="mt-8 text-base text-gray-500 font-inter"> </p>

                <div className="pb-12 bg-white">
                    <div className="relative">
                        <div className="relative mx-auto">
                            <div className="lg:max-w-4xl lg:mx-auto">
                                <div className="drop-shadow-md">
                                    <img className="rounded-lg" src="https://storage.googleapis.com/instadiv_img/how.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HowItWorks;