import React from 'react';

const Pricing = () => {

  // return (
  //   <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
  //     <div id="pricing" className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
  //       <div className="text-center">
  //         <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">Pricing</h2>
  //         <p className="max-w-md mx-auto mt-5 text-base font-normal text-gray-600 font-pj">Right now Instadiv is running in beta mode and its free. No credit card reqiured. </p>
  //       </div>

  //       <p className="mt-8 text-base text-gray-500 font-inter"> </p>

  //     </div>
  //   </section>
  // )


  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
      <div id="pricing" className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Pricing
          </h2>
          <p className="mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:mt-6 lg:leading-8">
          Right now Instadiv is running in beta mode and its free. No credit card reqiured. Below are the plans that we will offer once we conclude beta.
          </p>
        </div>

        <div className="grid max-w-3xl grid-cols-1 mx-auto mt-12 lg:grid-cols-2 lg:gap-8 sm:mt-16 lg:mt-20">

          <div
            className="relative bg-white border border-gray-200 divide-y divide-gray-200 rounded-2xl sm:rounded-3xl mt-14 lg:mt-0">

            <div className="px-4 py-5 bg-gray-50 sm:p-6 rounded-t-2xl sm:rounded-t-3xl">
              <div className="flex items-start">
                <div className="ml-6">
                  <h3 className="text-lg font-semibold text-gray-900">
                    With firebase integration
                  </h3>
                  <p className="mt-2 text-sm font-normal text-gray-500">
                  Under this plan, InstaDiv leverages the client's Firebase Firestore DB for storing and retrieving views. Firebase offers a generous free tier and pricing structure.
                  </p>
                </div>
              </div>
            </div>

            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-end">
                <p className="text-5xl font-semibold text-gray-900">
                  $59
                </p>
                <p className="py-1 text-sm font-normal text-gray-500">
                  /month (Billed annually)
                </p>
              </div>

              <div className="mt-6">
                <a href="https://app.instadiv.com" target="_blank" rel="noreferrer" title=""
                  className="inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl  hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                  role="button">
                  Free till we are in beta
                </a>
              </div>


              <ul className="mt-8 space-y-4">
                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd" />
                  </svg>
                  Unlimited surveys
                </li>

                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd" />
                  </svg>
                  Unlimited In-app messages
                </li>

                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd" />
                  </svg>
                  Unlimited embeddable banners
                </li>

                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd" />
                  </svg>
                  Unlimited MTUs
                </li>

                <li className="flex items-center">
                  <svg className="w-5 h-5 mr-2 text-blue-600 shrink-0" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd" />
                  </svg>
                  Unlimited responses
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-6 bg-white border border-gray-200 divide-y divide-gray-200 rounded-2xl sm:rounded-3xl lg:mt-0">
            <div className="px-4 py-5 bg-gray-50 sm:p-6 rounded-t-2xl sm:rounded-t-3xl">
              <div className="flex items-start">
                <div className="ml-6">
                  <h3 className="text-lg font-semibold text-gray-900">
                    Full tenancy plan
                  </h3>
                  <p className="mt-2 text-sm font-normal text-gray-500">
                  All data will be stored and retrieved from the InstaDiv server. Pricing will be determined based on Monthly Tracked Users (MTU) and follows a structured tier-wise approach.
                  </p>
                </div>
              </div>
            </div>

            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-end">
                <p className="text-3xl font-semibold text-gray-900">
                  Connect with us.
                </p>
              </div>

              <div className="flex items-end">
                <p className="py-1 text-sm font-normal text-gray-500">
                  support@instadiv.com
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Pricing;