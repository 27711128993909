import React from 'react';

const Hero = () => {    
    
    return (
        <div className="overflow-x-hidden bg-gray-50">

    <section className="pt-12 bg-gray-50 sm:pt-16">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-5xl mx-auto text-center">
                <p className="text-xs font-semibold tracking-widest text-gray-500 uppercase">
                    In-app Survey & engagement Software for Mobile Apps
                </p>
                <h1 className="mt-5 px-6 text-lg text-gray-600 font-inter">Collect valuable feedback, in-the-moment, right when it matters. Boost user retention by actively listening to your users. Deploy event-triggered surveys specifically designed for mobile apps! Achieve better efficiency than email surveys. Configure and launch within minutes.</h1>
                <p className="mt-5 text-4xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-5xl lg:leading-tight font-pj">
                        Insight and engagement that leads to {' '}
                </p>
                <p className="text-4xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-5xl lg:text-5xl lg:leading-tight font-pj">
                        <span style={{color: 'dodgerblue'}}>User satisfaction.</span>{' '}
                </p>

                <p className="mt-8 text-base text-gray-500 font-inter"> </p>

                <div className="relative max-w-lg mx-auto mt-14">

                    <a
                        href="https://app.instadiv.com" target="_blank" rel="noreferrer"
                        title=""
                        className="inline-flex items-center justify-center w-full px-10 py-4 text-lg font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                        role="button"
                        >
                        Get started for free
                    </a>
                </div>

                <p className="mt-8 text-base text-gray-500 font-inter"> </p>

            </div>
        </div>

        <div className="pb-12 bg-white">
            <div className="relative">
                <div className="absolute inset-0 h-2/3 bg-gray-50"></div>
                <div className="relative mx-auto">
                    <div className="lg:max-w-6xl lg:mx-auto">
                        <div className="drop-shadow-md">
                            <img className="rounded-lg" src="https://storage.googleapis.com/instadiv_img/hero.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

    )
}
export default Hero;