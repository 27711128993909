import '../App.css';
import Hero from '../sections/hero';
import Features from '../sections/features';
import HowItWorks from '../sections/howitworks';
import Pricing from '../sections/pricing';
import Footer from '../sections/footer';
import InAppEngagement from '../sections/in-app-engage';
import InAppPromotion from '../sections/in-app-promote';
import Header from '../sections/header';

function Home() {
  return (
    <>
      <Header/>
      <Hero/>
      <InAppPromotion/>
      <InAppEngagement/>
      <Features/>
      <HowItWorks/>
      <Pricing/>
      <Footer/>
    </>
  );
}

export default Home;