import React from 'react';
import '../App.css'

const Footer = () => {
    return (
        <section className="py-10 bg-gray-50 sm:pt-16 lg:pt-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="grid grid-cols-2 md:col-span-3 lg:grid-cols-6 gap-y-16 gap-x-12">
                    <div className="col-span-2 md:col-span-3 lg:col-span-2 lg:pr-8">
                        {/* <img className="w-auto h-12" src="https://storage.googleapis.com/instadiv_img/logo-name.png" alt="" /> */}
                        <a href="#" title="" className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                            <img className="w-auto h-14" src="https://storage.googleapis.com/instadiv_img/logo-name.png" alt="" />
                            <span className="beta-label">Beta</span>
                        </a>

                        <p className="text-base leading-relaxed text-gray-600 mt-7">An in-app engagement and promotions platform</p>

                        <li class="flex items-center">
                            <span class="text-base font-medium text-gray-500"> Email: </span>
                            <span class="inline-flex ml-1 text-base font-medium text-gray-900"> support@instadiv.com </span>
                        </li>


                    </div>

                    <div>
                        <p class="text-xs font-bold tracking-widest text-gray-900 uppercase">Help</p>
                        <ul class="mt-8 space-y-5">
                            {/* <li>
                                <a href="#" title="" class="inline-flex text-sm font-normal text-gray-900 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:translate-x-1"> Customer Support </a>
                            </li> */}
                            <li>
                                <a href="https://instadiv-privacy.web.app/" target="_blank" rel="noreferrer" title="" class="inline-flex text-sm font-normal text-gray-900 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:translate-x-1"> Privacy Policy </a>
                            </li>
                        </ul>
                    </div>

                </div>



                <hr className="mt-16 mb-10 border-gray-200" />

                <p className="text-sm text-center text-gray-600">© Copyright 2023, All Rights Reserved by InstaDiv</p>
            </div>
        </section>

    )
}
export default Footer;