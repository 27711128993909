import React from 'react';

const InAppEngagement = () => {
    return (
        <section className="py-12 bg-white sm:py-16 lg:py-20">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:items-center gap-y-8 md:grid-cols-2 md:gap-x-16">
                    <div>
                        <img className="w-full max-w-sm mx-auto" src="https://storage.googleapis.com/instadiv_img/inapp-survey.png" alt="" />
                    </div>

                    <div className="text-center md:text-left lg:pr-16">
                        <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">In-app surveys : collect feedback seamlessly</h2>
                        <p className="mt-4 text-lg text-gray-700 sm:mt-8 font-pj">
                            Collect in-the-moment feedback from your users with formats like survey, rating, NPS, free text etc. 
                            Customer feedback is one of the most effective ways to increase retention rates.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InAppEngagement;